import { ReactNode } from "react";

import { AtlasSidebar } from "@atlas/components/AtlasSidebar";

export const AtlasLayout = ({ children }: { children: ReactNode }) => (
  <div className="flex h-screen">
    <AtlasSidebar />
    <div className="flex grow flex-col overflow-y-auto">{children}</div>
  </div>
);
