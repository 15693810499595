import clsx from "clsx";

import { Company } from "@m/api/v4/types";
import { Suspensed } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { DATA_MISSING_PLACEHOLDER } from "../constants";

interface CustomerDetailsHeaderProps {
  databaseId?: Company["databaseId"];
  loading: boolean;
  mcn?: Company["mcn"];
  name?: Company["name"];
  status?: Company["status"];
  timestampCreated?: Company["timestampCreated"];
}

export const CustomerDetailsHeader = ({
  databaseId,
  loading,
  mcn,
  name,
  status,
  timestampCreated,
}: CustomerDetailsHeaderProps) => {
  const isActive = status === "Active";
  const displayName = name || DATA_MISSING_PLACEHOLDER;
  const displayMcn = mcn || DATA_MISSING_PLACEHOLDER;
  const dateAcquired = timestampCreated
    ? formatFullDate(timestampCreated)
    : DATA_MISSING_PLACEHOLDER;

  return (
    <div id="Header" className="mb-2">
      <div className="flex items-center space-x-4">
        <div className="flex h-full w-full space-x-2 text-3xl font-bold text-default">
          <Suspensed loading={loading} width={"300px"} height={"40px"}>
            {displayName}
          </Suspensed>

          {!loading && (
            <div
              className={clsx(
                "ml-3 inline-flex items-center text-sm font-bold",
                {
                  "text-action": isActive,
                  "text-accent": !isActive,
                }
              )}
            >
              {isActive ? "ACTIVE" : "INACTIVE"}
            </div>
          )}
        </div>
      </div>

      <div className="mt-1 flex space-x-3">
        <div className="text-sm font-semibold text-subdued">
          <Suspensed loading={loading} width={"95px"} height={"20px"}>
            <div aria-label="MCN">{displayMcn}</div>
          </Suspensed>
        </div>

        <Suspensed loading={loading} width={"180px"} height={"20px"}>
          <div className="inline-flex justify-items-center space-x-1 text-sm font-semibold">
            <div className="text-subdued" id="database-id">
              Database ID{" "}
            </div>
            <div className="text-default" aria-labelledby="database-id">
              {databaseId}
            </div>
          </div>
        </Suspensed>

        <Suspensed loading={loading} width={"180px"} height={"20px"}>
          <div className="inline-flex justify-items-center space-x-1 text-sm font-semibold">
            <div className="text-subdued" id="date-acquired">
              Customer Since{" "}
            </div>
            <div aria-labelledby="date-acquired" className="text-default">
              {dateAcquired}
            </div>
          </div>
        </Suspensed>
      </div>
    </div>
  );
};
