import { Banner, Button, Buttons, Modal } from "@m/ui";

import { useDisableMarketplaceMetering } from "../api";

interface StopMeteringModalProps {
  customerIdentifier: string;
  isOpen: boolean;
  onClose: () => void;
}

export const StopMeteringModal = ({
  customerIdentifier,
  isOpen,
  onClose,
}: StopMeteringModalProps) => {
  const [disableMarketplaceMetering, { loading }] =
    useDisableMarketplaceMetering(customerIdentifier);

  const handleStopMetering = () => {
    disableMarketplaceMetering();
    onClose();
  };

  return (
    <Modal
      header={<Modal.Title>Stop Metering</Modal.Title>}
      onClose={onClose}
      open={isOpen}
      size="md"
      className="flex flex-col gap-2"
    >
      <Banner
        label="Metering will need to be manually re-enabled"
        status="warning"
      />
      <Modal.Actions>
        <Buttons>
          <Button
            aria-label="Stop Metering Submit"
            kind="primary"
            loading={loading}
            onClick={handleStopMetering}
          >
            Stop Metering
          </Button>
        </Buttons>
      </Modal.Actions>
    </Modal>
  );
};
