import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { GetCustomerSubscriptionDetailsQuery } from "@m/api/v4/types";

export const GET_CUSTOMER_SUBSCRIPTION_DETAILS = gql(/* GraphQL */ `
  query GetCustomerSubscriptionDetails(
    $companySlug: String
    $where: MarketplaceRegistrationFilter
  ) {
    marketplaceRegistrations(companySlug: $companySlug, where: $where) {
      edges {
        node {
          id
          awsAccountId
          privateOfferId
          payerAccountId
          meteringStatus
          registrationStatus
          marketplaceProductCode
          customerIdentifier
          signupToken
          subscribeTime
          signupTime
          onboardingCompleteTimestamp
          expirationTime
          unsubscribeTime
          catalogItem {
            id
            productCode
            awsProductId
            description
            displayName
            dimensions {
              edges {
                node {
                  id
                  apiName
                  atlasName
                  atlasDescription
                  meteringMethod
                  dimensionTypeId
                }
              }
            }
            category
            status
            sku
          }
        }
      }
    }
  }
`);

export const useCustomerSubscriptionDetails = (
  companySlug: string,
  marketplaceProductCode: string
) => {
  const { data, ...result } = useQuery(GET_CUSTOMER_SUBSCRIPTION_DETAILS, {
    variables: { companySlug, where: { marketplaceProductCode } },
    fetchPolicy: "cache-and-network",
  });

  const marketplaceRegistration =
    data?.marketplaceRegistrations?.edges[0]?.node;

  const dimensions =
    marketplaceRegistration?.catalogItem?.dimensions?.edges?.map(
      (edge) => edge?.node
    ) ?? [];

  const catalogItem = marketplaceRegistration?.catalogItem && {
    ...marketplaceRegistration.catalogItem,
    dimensions: dimensions,
  };

  const subscriptionDetails = marketplaceRegistration && {
    ...marketplaceRegistration,
    catalogItem,
    companySlug,
  };

  return { data: { subscriptionDetails }, ...result };
};

type MarketplaceRegistration =
  GetCustomerSubscriptionDetailsQuery["marketplaceRegistrations"]["edges"][number]["node"];

export type Dimension =
  MarketplaceRegistration["catalogItem"]["dimensions"]["edges"][number]["node"];

export type CatalogItem = Omit<
  MarketplaceRegistration["catalogItem"],
  "dimensions"
> & {
  dimensions: Dimension[];
};

export type SubscriptionDetails = Omit<
  MarketplaceRegistration,
  "catalogItem"
> & { catalogItem: CatalogItem; companySlug: string };
