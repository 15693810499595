import {
  CatalogItemStatus,
  MarketplaceMeteringStatus,
  MarketplaceRegistrationStatus,
} from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";
import { dt } from "@m/utils";

import { type SubscriptionDetails } from "../api";

import { EXAMPLE_CATALOG_ITEM } from "./catalogItem.examples";

export const EXAMPLE_SUBSCRIPTION = {
  id: generateMockId(),
  awsAccountId: generateMockId(),
  customerIdentifier: generateMockId(),
  catalogItem: EXAMPLE_CATALOG_ITEM,
  companySlug: "test-company",
  expirationTime: dt.now().plus({ years: 1 }).toISO(),
  privateOfferId: generateMockId(),
  subscribeTime: dt.now().minus({ days: 1 }).toISO(),
  signupTime: dt.now().minus({ days: 1 }).toISO(),
  marketplaceProductCode: generateMockId(),
  registrationStatus: MarketplaceRegistrationStatus.Active,
  onboardingCompleteTimestamp: dt.now().minus({ days: 1 }).toISO(),
  unsubscribeTime: null,
  meteringStatus: MarketplaceMeteringStatus.Active,
  signupToken: generateMockId(),
  payerAccountId: generateMockId(),
} satisfies SubscriptionDetails;

export const EXAMPLE_SUBSCRIPTIONS = [
  EXAMPLE_SUBSCRIPTION,
  {
    ...EXAMPLE_SUBSCRIPTION,
    id: generateMockId(),
    awsAccountId: generateMockId(),
    customerIdentifier: generateMockId(),
    marketplaceProductCode: generateMockId(),
    privateOfferId: generateMockId(),
    catalogItem: {
      ...EXAMPLE_SUBSCRIPTION.catalogItem,
      displayName: "Small Product",
      status: CatalogItemStatus.Active,
      sku: "MC-SAAS-SMALL-PRODUCT-2024-07",
      id: generateMockId(),
      productCode: generateMockId(),
    },
  },
] satisfies SubscriptionDetails[];
