import { CatalogItemCategory } from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";
import { CatalogItemStatus } from "@m/api/v4/types";

import { CatalogItem } from "../api";

import { EXAMPLE_DIMENSIONS } from "./dimension.examples";

export const EXAMPLE_CATALOG_ITEM = {
  id: generateMockId(),
  dimensions: EXAMPLE_DIMENSIONS,
  description: "This is a big product!",
  displayName: "Big Product",
  sku: "MC-SAAS-BIG-PRODUCT-2024-07",
  category: CatalogItemCategory.SaasContract,
  awsProductId: generateMockId(),
  productCode: generateMockId(),
  status: CatalogItemStatus.Active,
} satisfies CatalogItem;
