import { Card } from "@m/ui";

import { CustomerDetailsField } from "./CustomerDetailsField";

interface CustomerDetailsCardProps {
  fields: [string, Array<string> | string | number][];
  loading: boolean;
  title: string;
}

export const CustomerDetailsCard = ({
  fields,
  loading,
  title,
}: CustomerDetailsCardProps) => {
  return (
    <Card
      title={<div className="text-sm font-semibold text-default">{title}</div>}
      className="w-full"
    >
      <div className="flex w-full flex-col space-y-2 overflow-auto p-2">
        {fields.map(([label, value]) => (
          <CustomerDetailsField
            key={label}
            label={label}
            value={value}
            loading={loading}
          />
        ))}
      </div>
    </Card>
  );
};
