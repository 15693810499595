import { PATHS } from "@atlas/constants";

import { awsAccountsRoutes } from "./awsAccountsRoutes";
import { customersRoutes } from "./customersRoutes";

export const authenticatedAtlasRoutes = [
  {
    path: "customers",
    handle: {
      crumb: () => <span>Customers</span>,
      to: PATHS.CUSTOMERS,
    },
    children: customersRoutes,
  },
  {
    path: "accounts",
    handle: {
      crumb: () => <span>AWS Accounts</span>,
      to: PATHS.AWS_ACCOUNTS,
    },
    children: awsAccountsRoutes,
  },
];
