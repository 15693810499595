import { Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { UIProvider } from "@m/ui";

import { AuthProvider } from "./AuthProvider";

export const GlobalProviders = ({ children }: PropsWithChildren) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_ATLAS_APP_AUTH0_CLIENT_ID as string}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    <UIProvider>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{ removeDefaultsFromUrl: true }}
      >
        <AuthProvider>{children}</AuthProvider>
      </QueryParamProvider>
    </UIProvider>
  </Auth0Provider>
);
