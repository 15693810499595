import { Navigate, Outlet } from "react-router-dom";

import { PATHS } from "@atlas/constants";
import { CustomerDetailsLayout } from "@atlas/layouts";
import { CustomerSubscriptionDetailsPage } from "@atlas/pages/CustomerSubscriptionDetailsPage";

import {
  CustomerDetailsPage,
  CustomerSubscriptionsPage,
  CustomersPage,
} from "../pages";

export const customersRoutes = [
  {
    element: <CustomersPage />,
    index: true,
  },
  {
    path: ":companySlug",
    element: (
      <CustomerDetailsLayout>
        <Outlet />
      </CustomerDetailsLayout>
    ),
    handle: {
      crumb: () => <CustomerDetailsLayout.Crumb />,
      to: PATHS.CUSTOMER_DETAILS,
    },
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: "overview",
        element: <CustomerDetailsPage />,
      },
      {
        path: "subscriptions",
        handle: {
          crumb: () => <span>Subscriptions</span>,
          to: PATHS.CUSTOMER_SUBSCRIPTIONS,
        },
        children: [
          {
            index: true,
            element: <CustomerSubscriptionsPage />,
          },
          {
            path: ":marketplaceProductCode",
            element: <CustomerSubscriptionDetailsPage />,
            handle: {
              crumb: () => <CustomerSubscriptionDetailsPage.Crumb />,
              to: PATHS.CUSTOMER_SUBSCRIPTION_DETAILS,
            },
          },
        ],
      },
    ],
  },
];
