import { generateMockId } from "@m/api/testing";
import { dt } from "@m/utils";

import { MeteredChargeType } from "../api";

const templateDate = dt.fromISO("2024-10-10T01:00:00Z");

export class ExampleMeteredCharge implements MeteredChargeType {
  id: string;
  company: { id: string; slug: string };
  marketplaceProductCode: string;
  dimension: { id: string; atlasName: string; apiName: string };
  dimensionCode: string;
  quantity: number;
  billingPeriod: { isoformat: string; year: number };
  chargeDate: string;
  reportedDate: string;
  account: { id: string; name: string };

  constructor({
    id = generateMockId(),
    companyId = "789",
    marketplaceProductCode = generateMockId(),
    dimensionId = generateMockId(),
    atlasName = "10 Additional Monthly Pool Hours",
    apiName = "10monthlyPoolHours",
    dimensionCode = generateMockId(),
    quantity = 20,
    billingPeriodDate = templateDate,
    chargeDate = templateDate,
    reportedDate = templateDate.minus({ days: 2 }),
    accountId = "123",
    accountName = "John Smith",
  } = {}) {
    this.id = id;
    this.company = {
      id: companyId,
      slug: "example-company",
    };
    this.marketplaceProductCode = marketplaceProductCode;
    this.dimension = {
      id: dimensionId,
      atlasName,
      apiName,
    };
    this.dimensionCode = dimensionCode;
    this.quantity = quantity;
    this.billingPeriod = {
      isoformat: billingPeriodDate.toISO(),
      year: billingPeriodDate.year,
    };
    this.chargeDate = chargeDate.toISO();
    this.reportedDate = reportedDate.toISO();
    this.account = {
      id: accountId,
      name: accountName,
    };
  }
}

export const EXAMPLE_METERED_CHARGE = new ExampleMeteredCharge();
export const EXAMPLE_METERED_CHARGES = [
  EXAMPLE_METERED_CHARGE,
  new ExampleMeteredCharge({
    id: generateMockId(),
    companyId: "789",
    marketplaceProductCode: generateMockId(),
    dimensionId: generateMockId(),
    atlasName: "20 Adjusted Pool Hours",
    apiName: "20adjustedPoolHours",
    dimensionCode: generateMockId(),
    quantity: 30,
    billingPeriodDate: templateDate.minus({ years: 1 }),
    chargeDate: templateDate.minus({ weeks: 1 }),
    reportedDate: templateDate.minus({ weeks: 1, days: 2 }),
    accountId: "234",
    accountName: "Luca Pasedena",
  }),
  new ExampleMeteredCharge({
    id: generateMockId(),
    companyId: "789",
    marketplaceProductCode: generateMockId(),
    dimensionId: generateMockId(),
    atlasName: "Adjustment Charges",
    apiName: "adjustmentCharges",
    dimensionCode: generateMockId(),
    quantity: 10,
    billingPeriodDate: templateDate.minus({ weeks: 1 }),
    chargeDate: templateDate.minus({ weeks: 1 }),
    reportedDate: templateDate.minus({ weeks: 1, days: 2 }),
    accountId: undefined,
    accountName: undefined,
  }),
];

export const EXAMPLE_METERED_CHARGES_HOURLY = Array.from(
  { length: 14 },
  (_, index) =>
    new ExampleMeteredCharge({
      id: generateMockId(),
      quantity: index === 4 ? 1 : 0,
      chargeDate: index < 4 ? templateDate.plus({ hours: index }) : undefined,
      reportedDate: templateDate.plus({ hours: index }).minus({ days: 2 }),
      billingPeriodDate: templateDate.minus({ hours: index }),
    })
);

export const EXAMPLE_METERED_CHARGES_HOURLY_PAGINATED = Array.from(
  { length: 30 },
  (_, index) =>
    new ExampleMeteredCharge({
      id: generateMockId(),
      quantity: index === 4 ? 1 : 0,
      chargeDate: index < 4 ? templateDate.plus({ hours: index }) : undefined,
      reportedDate: templateDate.plus({ hours: index }).minus({ days: 2 }),
      billingPeriodDate: templateDate.plus({ hours: index }),
    })
);
