import { useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Params, generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Suspensed, useModalState } from "@m/ui";

import { PATHS } from "@atlas/constants";

import {
  GET_CUSTOMER_SUBSCRIPTION_DETAILS,
  useCustomerSubscriptionDetails,
} from "./api";
import {
  MeteredChargesTable,
  ProductInformationModal,
  SubscriptionDetailsTable,
} from "./components";

interface CustomerSubscriptionDetailsPageParams extends Params {
  companySlug: string;
  marketplaceProductCode: string;
}

export const CustomerSubscriptionDetailsPage = () => {
  const navigate = useNavigate();

  const { companySlug = "", marketplaceProductCode = "" } =
    useParams<CustomerSubscriptionDetailsPageParams>();

  const {
    data: { subscriptionDetails },
    loading: loadingSubscriptionDetails,
  } = useCustomerSubscriptionDetails(companySlug, marketplaceProductCode);

  const { catalogItem } = subscriptionDetails ?? {};
  const productInfoModal = useModalState();

  if (!companySlug) navigate(generatePath(PATHS.ROOT));
  if (!catalogItem && !loadingSubscriptionDetails) {
    toast.error("Could not find customer subscription.");
    navigate(generatePath(PATHS.CUSTOMER_SUBSCRIPTIONS, { companySlug }));
  }

  return (
    <div className="flex flex-col">
      <div className="flex pl-1 text-base font-semibold text-default">
        <Suspensed
          loading={loadingSubscriptionDetails}
          height="24px"
          width="280px"
        >
          <h1>{catalogItem?.displayName}</h1>
          <Button
            type="button"
            fill="none"
            className="m-0 ml-1 !p-0"
            iconClassName="h-2 w-2"
            leftIcon={InformationCircleIcon}
            onClick={productInfoModal.open}
          />
        </Suspensed>
      </div>
      <SubscriptionDetailsTable
        loading={loadingSubscriptionDetails}
        subscriptionDetails={subscriptionDetails}
      />
      <MeteredChargesTable subscriptionDetails={subscriptionDetails} />
      <ProductInformationModal
        catalogItem={subscriptionDetails?.catalogItem ?? null}
        isOpen={productInfoModal.isOpen}
        onClose={productInfoModal.close}
      />
    </div>
  );
};

CustomerSubscriptionDetailsPage.Crumb = function Crumb() {
  const { companySlug, marketplaceProductCode } =
    useParams<CustomerSubscriptionDetailsPageParams>();

  const { data, loading } = useQuery(GET_CUSTOMER_SUBSCRIPTION_DETAILS, {
    variables: { companySlug, where: { marketplaceProductCode } },
    fetchPolicy: "cache-first",
  });

  const displayName =
    data?.marketplaceRegistrations?.edges?.[0]?.node?.catalogItem
      ?.displayName ?? "Product";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      {displayName}
    </Suspensed>
  );
};
