import ReactDatePicker from "react-datepicker";

interface MeteredChargesDailyDatePickerProps {
  disabled?: boolean;
  endDate: Date;
  onChange: (date: [Date, Date], event: React.SyntheticEvent<any>) => void;
  placeholder?: string;
  selectsRange?: boolean;
  startDate: Date;
}

export const MeteredChargesDailyDatePicker = ({
  disabled,
  endDate,
  onChange,
  placeholder,
  selectsRange,
  startDate,
}: MeteredChargesDailyDatePickerProps) => {
  return (
    <ReactDatePicker
      className="h-4 w-[210px] rounded-md border border-gray-300 p-1"
      clearButtonTitle="Clear"
      dateFormat="M/d/yyyy"
      disabled={disabled}
      enableTabLoop={false}
      endDate={endDate}
      isClearable={true}
      monthClassName={() => "p-1"}
      onChange={onChange}
      placeholderText={placeholder}
      selected={startDate}
      selectsRange={selectsRange}
      startDate={startDate}
      wrapperClassName="w-[210px]"
    />
  );
};
