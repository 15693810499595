import { AggregateBillingPeriod } from "@m/api/v4/types";

import { MeteredChargesDailyDatePicker } from "./MeteredChargesDailyDatePicker";
import { MeteredChargesHourlyDatePicker } from "./MeteredChargesHourlyDatePIcker";
import { MeteredChargesMonthlyDatePicker } from "./MeteredChargesMonthlyDatePicker";

interface Props {
  cadence: AggregateBillingPeriod;
  disabled?: boolean;
  endDate: Date | null | undefined;
  onChange: (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
  ) => void;
  placeholder: string;
  startDate: Date | null | undefined;
}
export const MeteredChargeDatePicker = ({
  cadence,
  disabled,
  endDate,
  onChange,
  placeholder,
  startDate,
}: Props) => {
  if (cadence === AggregateBillingPeriod.Hourly) {
    return (
      <MeteredChargesHourlyDatePicker
        disabled={disabled}
        endDate={endDate}
        onChange={onChange}
        placeholder={placeholder}
        selectsRange={true}
        startDate={startDate}
      />
    );
  }

  if (cadence === AggregateBillingPeriod.Daily) {
    return (
      <MeteredChargesDailyDatePicker
        disabled={disabled}
        endDate={endDate}
        onChange={onChange}
        placeholder={placeholder}
        selectsRange={true}
        startDate={startDate}
      />
    );
  }

  if (cadence === AggregateBillingPeriod.Monthly) {
    return (
      <MeteredChargesMonthlyDatePicker
        disabled={disabled}
        endDate={endDate}
        onChange={onChange}
        placeholder={placeholder}
        selectsRange={true}
        startDate={startDate}
      />
    );
  }

  return null;
};
