import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import { GlobalProviders } from "@atlas/providers";
import { atlasRoutes } from "@atlas/routes";

export const AtlasApp = () => {
  const router = createBrowserRouter([
    {
      element: (
        <GlobalProviders>
          <Outlet />
        </GlobalProviders>
      ),
      children: atlasRoutes,
    },
  ]);

  return <RouterProvider router={router} />;
};
