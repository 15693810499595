import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { generatePath, useParams } from "react-router-dom";

import { MarketplaceRegistration } from "@m/api/v4/types";
import { Card, Link, Timestamp } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { SubscriptionInfoBadge } from "@atlas/components";
import { PATHS } from "@atlas/constants";

interface CustomerSubscriptionsCardProps {
  catalogItemDisplayName?: string;
  expirationTime?: string;
  lastSuccessfulMeteredUsageTimestamp?: string;
  marketplaceProductCode: string;
  registrationStatus: MarketplaceRegistration["registrationStatus"];
}

export const CustomerSubscriptionsCard = ({
  catalogItemDisplayName,
  expirationTime,
  lastSuccessfulMeteredUsageTimestamp,
  marketplaceProductCode,
  registrationStatus,
}: CustomerSubscriptionsCardProps) => {
  const { companySlug } = useParams<{ companySlug: string }>();

  if (!catalogItemDisplayName) return null;

  const formattedExpiration = formatFullDate(expirationTime, "MMMM d, yyyy");

  return (
    <Card className="flex h-[160px] w-[340px] flex-col">
      <div className="max-h-[120px] flex-grow space-y-1 border-b border-gray-300 p-2">
        <h2 className="mb-1 flex justify-between">
          <span className="mr-1 text-sm font-bold">
            {catalogItemDisplayName}
          </span>
          <SubscriptionInfoBadge status={registrationStatus} />
        </h2>
        <dl className="space-y-1">
          <div className="flex justify-between text-xs font-medium">
            <dt>Expires</dt>
            <dd className="text-subdued">{formattedExpiration || "—"}</dd>
          </div>
          <div className="flex justify-between text-xs font-medium">
            <dt>Last Charge</dt>
            <dd>
              {lastSuccessfulMeteredUsageTimestamp ? (
                <Timestamp
                  isoTime={lastSuccessfulMeteredUsageTimestamp}
                  className="text-xs font-medium text-subdued"
                  longFormat={true}
                  titleCase={true}
                />
              ) : (
                "—"
              )}
            </dd>
          </div>
        </dl>
      </div>
      <Link
        aria-label="To subscription details page"
        to={generatePath(PATHS.CUSTOMER_SUBSCRIPTION_DETAILS, {
          companySlug,
          marketplaceProductCode,
        })}
        rightIcon={ArrowRightIcon}
        className="my-1 ml-auto p-0.5 pr-2 text-xs font-semibold"
      >
        Subscription Details
      </Link>
    </Card>
  );
};
