import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { AtlasApp } from "./app";
import { initializeSentry } from "./utils";

if (process.env.NODE_ENV === "production") {
  initializeSentry();
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <StrictMode>
    <AtlasApp />
  </StrictMode>
);
