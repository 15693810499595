import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";
import {
  CompaniesFilter,
  CompanySortEnum,
  CustomerListQuery,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, usePaginationProps } from "@m/ui";

export const GET_CUSTOMER_LIST = gql(`
  query CustomerList(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [CompanySortEnum]
    $where: CompaniesFilter
    $catalogItemIds: [ID]
  ) {
    companies(
      before: $before
      after: $after
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
      catalogItemIds: $catalogItemIds
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          databaseId
          entitlements
          hasActiveMarketplaceRegistration
          id
          mcn
          name
          slug
          status
          types
        }
      }
    }
  }
`);

interface CustomerListParams {
  marketplace: boolean;
  active: boolean;
  catalogItemIds: string[];
  search: string;
  sort: CompanySortEnum;
}

export type CustomerListItem =
  CustomerListQuery["companies"]["edges"][number]["node"];

export const useCustomerList = (query: CustomerListParams) => {
  const where: CompaniesFilter = {
    or: [
      { typesContains: ["Customer"] },
      { typesContains: ["Former Customer"] },
    ],
  };
  if (query.active) where.status = "Active";
  if (query.marketplace) where.hasActiveMarketplaceRegistration = true;

  const { data, fetchMore, ...result } = useQuery(GET_CUSTOMER_LIST, {
    variables: {
      ...INITIAL_PAGINATION,
      searchTerm: query.search,
      sort: query.sort,
      catalogItemIds: query.catalogItemIds,
      where,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { edges = [], pageInfo = {}, totalCount = 0 } = data?.companies ?? {};

  const customers = edges.map((edge) => edge?.node) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { customers }, pagination, ...result };
};
