import clsx from "clsx";

import { Badge, Suspensed } from "@m/ui";
import { toHyphenLowercase } from "@m/utils";

import { DATA_MISSING_PLACEHOLDER } from "../constants";

interface CustomerDetailsFieldProps {
  label: string;
  loading: boolean;
  value: Array<string> | string | number;
}

export const CustomerDetailsField = ({
  label,
  loading,
  value = DATA_MISSING_PLACEHOLDER,
}: CustomerDetailsFieldProps) => {
  const dataMissing = value === DATA_MISSING_PLACEHOLDER;
  const id = `customer-detail-field-${toHyphenLowercase(label)}`;

  return (
    <div className="ml-1 flex justify-between gap-x-4 text-sm font-semibold text-default">
      <div id={id}>{label}</div>
      <div
        className={clsx("flex flex-wrap justify-end text-right font-normal", {
          "text-gray-400": dataMissing,
        })}
      >
        <Suspensed loading={loading} height="20px" width="96px">
          <div aria-labelledby={id}>
            {value instanceof Array
              ? [...value]
                  .sort((a, b) => a.localeCompare(b))
                  .map((item, i) => (
                    <Badge
                      key={`customer-detail-map-${i}`}
                      size="small"
                      className="ml-0.5"
                      label={item}
                    />
                  ))
              : value}
          </div>
        </Suspensed>
      </div>
    </div>
  );
};
