import { PropsWithChildren } from "react";

import { ApiProvider, ExtensionsProvider } from "@m/api";
import { useAuth } from "@m/login";

import { DebugConsole } from "@atlas/components";
import { cacheConfig } from "@atlas/constants";

export const AuthenticatedProviders = ({ children }: PropsWithChildren) => {
  const { logout } = useAuth();

  return (
    <ApiProvider
      cacheConfig={cacheConfig}
      context={{ clientName: "v4" }}
      errorOptions={{ logout }}
    >
      <ExtensionsProvider>
        {children}
        <DebugConsole />
      </ExtensionsProvider>
    </ApiProvider>
  );
};
