const APP_ID = process.env.REACT_APP_SERVICES_INTERCOM_APP_ID;

export const isIntercomLoaded = () =>
  typeof window.Intercom !== "undefined" ? true : false;

export const getIntercom = () => {
  if (!isIntercomLoaded()) return null;
  return window.Intercom;
};

export const showIntercomChat = () => {
  getIntercom()?.("show");
};

export function shutdownIntercom() {
  getIntercom()?.("shutdown");
}

export function hideIntercom() {
  getIntercom()?.("hide");
}
