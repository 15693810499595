import {
  ArrowUpOnSquareIcon,
  EllipsisVerticalIcon,
  NoSymbolIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import { AccessRequired } from "@m/api/components";
import { Dropdown, DropdownGroup, DropdownItem } from "@m/ui";

import { ATLAS_MUTATIONS } from "@atlas/constants";

interface MeteredChargesTableActionsProps {
  canAddMeteredCharge: boolean;
  canExportMeteredCharges: boolean;
  canStopMetering: boolean;
  disabled: boolean;
  onAddMeteredChargeClick: () => void;
  onExportMeteredChargesClick: () => void;
  onStopMeteringClick: () => void;
}

export const MeteredChargesTableActions = ({
  canAddMeteredCharge,
  canExportMeteredCharges,
  canStopMetering,
  disabled,
  onAddMeteredChargeClick,
  onExportMeteredChargesClick,
  onStopMeteringClick,
}: MeteredChargesTableActionsProps) => {
  return (
    <Dropdown
      className="w-fit p-1"
      direction="bottom-start"
      trigger={
        <EllipsisVerticalIcon
          aria-label="Open Subscription Actions"
          className="h-2.5 w-2.5"
        />
      }
    >
      <DropdownGroup aria-label="Subscription Actions">
        <DropdownItem
          disabled={!canAddMeteredCharge || disabled}
          icon={PlusIcon}
          onClick={onAddMeteredChargeClick}
        >
          Add Metered Charge
        </DropdownItem>
        <DropdownItem
          disabled={!canExportMeteredCharges || disabled}
          icon={ArrowUpOnSquareIcon}
          onClick={onExportMeteredChargesClick}
        >
          Export Metered Charges
        </DropdownItem>
      </DropdownGroup>
      <AccessRequired mutation={ATLAS_MUTATIONS.DISABLE_MARKETPLACE_METERING}>
        <DropdownGroup>
          <DropdownItem
            disabled={!canStopMetering || disabled}
            icon={NoSymbolIcon}
            onClick={onStopMeteringClick}
          >
            Stop Metering
          </DropdownItem>
        </DropdownGroup>
      </AccessRequired>
    </Dropdown>
  );
};
