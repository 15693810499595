import { PropsWithChildren, useMemo } from "react";

import { AuthContext } from "@m/login";
import { useAuth0Logout, useUserSession } from "@m/login/hooks";

import { AtlasLogin } from "@atlas/app/components";

import { AuthenticatedProviders } from "./AuthenticatedProviders";

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { user, updateUserSession } = useUserSession();
  const logout = useAuth0Logout();

  const isAuthenticated = !!user;

  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      user,
      updateUserSession,
      logout,
    }),
    [isAuthenticated, user, updateUserSession, logout]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {isAuthenticated ? (
        <AuthenticatedProviders>{children}</AuthenticatedProviders>
      ) : (
        <AtlasLogin />
      )}
    </AuthContext.Provider>
  );
};
