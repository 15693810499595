import { Params, useParams } from "react-router-dom";

import { Spinner } from "@m/ui";

import { useCustomerSubscriptions } from "../api";

import { CustomerSubscriptionsCard } from "./CustomerSubscriptionsCard";
import { CustomerSubscriptionTableEmpty } from "./CustomerSubscriptionsTableEmpty";

interface CustomerSubscriptionsTableParams extends Params {
  companySlug: string;
}

export const CustomerSubscriptionsTable = () => {
  const { companySlug = "" } = useParams<CustomerSubscriptionsTableParams>();

  const {
    data: { subscriptions = [] },
    loading,
  } = useCustomerSubscriptions(companySlug);

  if (loading) return <Spinner />;
  if (subscriptions.length === 0) return <CustomerSubscriptionTableEmpty />;

  return (
    <div className="flex w-full flex-wrap place-content-baseline gap-2">
      {subscriptions.map((subscription) => {
        if (!subscription) return;

        return (
          <CustomerSubscriptionsCard
            catalogItemDisplayName={subscription?.catalogItem?.displayName}
            expirationTime={subscription?.expirationTime}
            lastSuccessfulMeteredUsageTimestamp={
              subscription?.lastSuccessfulMeteredUsageTimestamp
            }
            marketplaceProductCode={subscription?.marketplaceProductCode}
            registrationStatus={subscription?.registrationStatus}
            key={subscription?.id}
          />
        );
      })}
    </div>
  );
};
