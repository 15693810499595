import ReactDatePicker from "react-datepicker";

interface MeteredChargesMonthlyDatePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (
    date: [Date, Date],
    event: React.SyntheticEvent<any> | undefined
  ) => void;
  disabled?: boolean;
  selectsRange?: boolean;
  placeholder?: string;
}

export const MeteredChargesMonthlyDatePicker = ({
  startDate,
  endDate,
  onChange,
  disabled,
  selectsRange,
  placeholder,
}: MeteredChargesMonthlyDatePickerProps) => {
  return (
    <ReactDatePicker
      className="h-4 w-[210px] rounded-md border border-gray-300 p-1"
      clearButtonTitle="Clear"
      dateFormat="MM/yyyy"
      disabled={disabled}
      enableTabLoop={false}
      endDate={endDate}
      isClearable={true}
      monthClassName={() => "p-1"}
      onChange={onChange}
      placeholderText={placeholder}
      selected={startDate}
      selectsRange={selectsRange}
      showMonthYearPicker={true}
      startDate={startDate}
      wrapperClassName="w-[210px]"
    />
  );
};
