/**
 * Paths constant used to keep track of all of the path segments used throughout Atlas.
 *
 * Relative paths are prefixed with an underscore (_).
 */
export const PATHS = {
  // Root
  ROOT: "/",

  // Customers
  CUSTOMERS: "/customers",
  CUSTOMER_DETAILS: "/customers/:companySlug/overview",
  CUSTOMER_SUBSCRIPTIONS: "/customers/:companySlug/subscriptions",
  CUSTOMER_SUBSCRIPTION_DETAILS:
    "/customers/:companySlug/subscriptions/:marketplaceProductCode",

  // AWS Accounts
  AWS_ACCOUNTS: "/accounts",
};
