import { Navigate, Outlet } from "react-router-dom";

import { Logout } from "@m/login";

import { PATHS } from "@atlas/constants";
import { AtlasLayout } from "@atlas/layouts";

import { authenticatedAtlasRoutes } from "./authenticatedAtlasRoutes";

export const atlasRoutes = [
  {
    index: true,
    element: <Navigate to={PATHS.CUSTOMERS} replace />,
  },
  {
    path: "*",
    element: (
      <AtlasLayout>
        <Outlet />
      </AtlasLayout>
    ),
    children: authenticatedAtlasRoutes,
  },
  {
    path: "logout",
    element: <Logout />,
  },
];
