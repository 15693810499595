import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";

export const GET_PRODUCT_FILTERS_FROM_CATALOG_ITEMS = gql(/* GraphQL */ `
  query GetProductFiltersFromCatalogItems {
    catalog {
      addons {
        edges {
          node {
            id
            sku
            name
          }
        }
      }
      bundles {
        edges {
          node {
            id
            sku
            name
          }
        }
      }
      discounts {
        edges {
          node {
            id
            sku
            name
          }
        }
      }
      features {
        edges {
          node {
            id
            sku
            name
          }
        }
      }
      products {
        edges {
          node {
            id
            sku
            name
          }
        }
      }
    }
  }
`);

export const useCustomerFilters = () => {
  const { data, loading, error } = useQuery(
    GET_PRODUCT_FILTERS_FROM_CATALOG_ITEMS
  );

  const filters =
    [
      data?.catalog?.addons,
      data?.catalog?.bundles,
      data?.catalog?.discounts,
      data?.catalog?.features,
      data?.catalog?.products,
    ]
      .flatMap((item) => item?.edges.map(({ node }) => node))
      .sort((a, b) => a?.name.localeCompare(b?.name))
      .filter(Boolean) ?? [];

  return {
    data: { filters },
    loading,
    error,
  };
};
